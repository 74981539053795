export const env = {
    id: process.env.REACT_APP_ID,
    project: process.env.REACT_APP_PROJECT || 'omv-dev',
    cluster: process.env.REACT_APP_CLUSTER || 'omv',
    apiKey: process.env.REACT_APP_API_KEY,
    apiKeyDev: process.env.REACT_APP_DEV_API_KEY,
    apiKeyQua: process.env.REACT_APP_QA_API_KEY,
    useApiKey: process.env.REACT_APP_USE_API_KEY,
    cad: {
        mpa: {
            modelId: process.env.REACT_APP_MPA_CAD_ID || 1416628804790401,
            revisionId: process.env.REACT_APP_MPA_CAD_REVISION_ID || 2191407447732232,
            modelIdDev: process.env.REACT_APP_DEV_MPA_CAD_ID || 1416628804790401,
            revisionIdDev: process.env.REACT_APP_DEV_MPA_CAD_REVISION_ID || 2191407447732232,
            modelIdQua: process.env.REACT_APP_QA_MPA_CAD_ID || 3987313595716904,
            revisionIdQua: process.env.REACT_APP_QA_MPA_CAD_REVISION_ID || 6926279796449512,
        },
        mps: {
            modelId: process.env.REACT_APP_MPS_CAD_ID || 3677791741151425,
            revisionId: process.env.REACT_APP_MPS_CAD_REVISION_ID || 6505237071322695,
            modelIdDev: process.env.REACT_APP_DEV_MPS_CAD_ID || 3677791741151425,
            revisionIdDev: process.env.REACT_APP_DEV_MPS_CAD_REVISION_ID || 6505237071322695,
            modelIdQua: process.env.REACT_APP_QA_MPS_CAD_ID || 8599048146114909,
            revisionIdQua: process.env.REACT_APP_QA_MPS_CAD_REVISION_ID || 5393252109789948,
        },
        mpb: {
            modelId: process.env.REACT_APP_MPB_CAD_ID || 3349538893875760,
            revisionId: process.env.REACT_APP_MPB_CAD_REVISION_ID || 7318180126924983,
            modelIdDev: process.env.REACT_APP_DEV_MPB_CAD_ID || 3349538893875760,
            revisionIdDev: process.env.REACT_APP_DEV_MPB_CAD_REVISION_ID || 7318180126924983,
            modelIdQua: process.env.REACT_APP_QA_MPB_CAD_ID || 8599048146114909,
            revisionIdQua: process.env.REACT_APP_QA_MPB_CAD_REVISION_ID || 5393252109789948,
        },
        opm: {
            modelId: process.env.REACT_APP_OPM_CAD_ID || 576093204547393,
            revisionId: process.env.REACT_APP_OPM_CAD_REVISION_ID || 3125837667630113,
            modelIdDev: process.env.REACT_APP_DEV_OPM_CAD_ID || 576093204547393,
            revisionIdDev: process.env.REACT_APP_DEV_OPM_CAD_REVISION_ID || 3125837667630113,
            modelIdQua: process.env.REACT_APP_QA_OPM_CAD_ID || 8599048146114909,
            revisionIdQua: process.env.REACT_APP_QA_OPM_CAD_REVISION_ID || 5393252109789948,
        },
    },
};