import { env } from '../env';
import { extractTokensFromUrl, generateLoginUrl } from '../utils/LoginUtils';

// login manager is merely global state, stored outside of react state,
// because react state is not preserved between mdx examples
export class LoginManager {
    private token: string | undefined;

    readonly isLoggedIn: boolean;
    readonly project: string;
    readonly cluster: string;

    constructor(project: string, cluster: string) {
        this.token = this.handleLoginFromRedirect();
        this.isLoggedIn = !!this.token;
        this.project = project;
        this.cluster = cluster;
    }

    private handleLoginFromRedirect() {
        const tokens = extractTokensFromUrl();
        return tokens?.accessToken;
    }

    async getToken(): Promise<string> {
        if (!this.token) {
            await this.loginWithRedirect();
        }
        return this.token!;
    }

    getTokenWithApiKey(): Promise<string> {
        return Promise.resolve(
            this.project.includes('dev') ? env.apiKeyDev : this.project.includes('test') ? env.apiKeyQua : env.apiKey
        );
    }

    loginWithRedirect(): Promise<void> {
        return new Promise<void>(() => {
            const url = generateLoginUrl({
                project: this.project,
                baseUrl: `https://${this.cluster}.cognitedata.com`,
                redirectUrl: `https://${this.cluster}.cognitedata.com`,
            });

            window.location.assign(url);
        });
    }
}

export const loginManager = new LoginManager(env.project, env.cluster);
