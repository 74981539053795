import { env } from '../env';
import { CogniteClient } from '@cognite/sdk';
import { LoginManager } from './LoginManager';

export class ClientManager {
    readonly client: CogniteClient;
    readonly baseUrl: string;
    readonly appId: string;

    constructor(projectName: string | null) {
        this.appId = env.id;
        this.baseUrl = `https://${env.cluster}.cognitedata.com`;
        console.log('new project : ', env.project);
        const loginManager = new LoginManager(projectName ?? env.project, env.cluster);
        this.client = new CogniteClient({
            appId: this.appId,
            baseUrl: this.baseUrl,
            project: loginManager.project,
            apiKeyMode: env.useApiKey,
            getToken: () => (env.useApiKey ? loginManager.getTokenWithApiKey() : loginManager.getToken()),
        });
        this.clientAuthentication();
    }

    private isClientAuthenticationRequired(): boolean {
        return !env.useApiKey;
    }

    async clientAuthentication(): Promise<string | undefined> {
        var token = undefined;
        if (this.isClientAuthenticationRequired()) {
            token = await this.client.authenticate();
        }
        return token;
    }
}
