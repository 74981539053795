import './App.css';
import { CogniteClient, Asset, Node3D, AuthTokens } from '@cognite/sdk';
import { HtmlOverlayTool } from '@cognite/reveal/tools';
import {
    Cognite3DViewer,
    Cognite3DModel,
    CadIntersection,
    DefaultNodeAppearance,
    TreeIndexNodeCollection,
    NumericRange,
} from '@cognite/reveal';
import React, { ChangeEvent, useEffect, useRef } from 'react';
import nsWebViewInterface from './nativescript-webview-interface';
import ImageIcon from '@material-ui/icons/Image';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { purple } from '@material-ui/core/colors';
import { Color, Vector3 } from 'three';
import { CogniteInternalId, IdInfo } from '@cognite/sdk-core';
import { ClientManager } from './reveal/ClientManager';
import { Cognite3DViewerManager } from './reveal/Cognite3DViewerManager';
import { NodeTree } from './reveal/interface/NodeTree';
import { AssetNode } from './reveal/interface/AssetNode';
import { AssetMetadata } from './reveal/interface/asset-metadata';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Card, CardContent, CardActions, Button } from '@material-ui/core';
import { initDB, useIndexedDB } from 'react-indexed-db';
import { DBConfig } from './DbConfig';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { AccordionActions, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import assert from 'assert';

initDB(DBConfig);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(3),
            },
        },
    })
);

const LogError = (text: string) => {
    const { add } = useIndexedDB('logger');
    add({ message: text }).then(
        (event) => {
            console.log('ID Generated: ', event);
        },
        (error) => {
            console.log(error);
        }
    );
};

interface logger {
    id: number;
    message: string;
}

function App() {
    const classes = useStyles();
    const divvi = useRef<HTMLDivElement>(null);
    const overlayDiv = useRef<HTMLDivElement>(null);
    const markupDiv = useRef<HTMLDivElement>(null);
    const hierchDiv = useRef<HTMLDivElement>(null);
    const errorPopup = useRef<HTMLDivElement>(null);
    const debugPopup = useRef<HTMLDivElement>(null);

    const imageMarkupTemplate = useRef<HTMLDivElement>(null);
    const imageMarkupRef = useRef<HTMLDivElement>();

    const oWebViewInterface = nsWebViewInterface;

    let flocList: Asset[] = [];
    let hierchArray: AssetNode[] = [];

    const clientRef = useRef<CogniteClient>();
    const modelRef = useRef<Cognite3DModel>();
    const viewerRef = useRef<Cognite3DViewer>();
    const cognite3DViewerManagerRef = useRef<Cognite3DViewerManager>();
    const rootNodeTreeRef = useRef<NodeTree>();
    var tokensRef = useRef<AuthTokens>();

    const [rootNodeTree, setRootNodeTree] = React.useState<NodeTree>();
    const [isModelGhosted, setIsModelGhosted] = React.useState(false);

    // Recursive component
    const NodeTreeList = (props: { nodeTree: NodeTree | undefined }) => {
        return (
            <div>
                <ul>
                    <li>
                        {props.nodeTree && props.nodeTree.name}
                        <ul>
                            {props.nodeTree &&
                                props.nodeTree.children.map((childDepth1) => (
                                    <li>
                                        {childDepth1.name}
                                        <ul>
                                            {childDepth1.children.length > 0 &&
                                                childDepth1.children.map((childDepth2) => <li>{childDepth2.name}</li>)}
                                        </ul>
                                    </li>
                                ))}
                        </ul>
                    </li>
                </ul>
                ))
            </div>
        );
    };

    const ImageMarkup = () => {
        return (
            <div className="Markup" ref={imageMarkupTemplate}>
                <ImageIcon style={{ color: purple[500] }} />
            </div>
        );
    };

    const Unghost = () => {
        const handleClick = () => {
            setIsModelGhosted((isModelGhosted) => !isModelGhosted);
            const model = modelRef.current;
            if (model) {
                model.setDefaultNodeAppearance(
                    isModelGhosted ? DefaultNodeAppearance.Default : DefaultNodeAppearance.Ghosted
                );
            } else {
                LogError('Unghost: model is null');
            }
        };
        return (
            <div className="Unghost">
                {isModelGhosted ? (
                    <VisibilityOffIcon onClick={handleClick} />
                ) : (
                    <VisibilityIcon onClick={handleClick} />
                )}
            </div>
        );
    };

    const Support = () => {
        const { getAll } = useIndexedDB('logger');
        const [supportVisibility, setSupportVisibility] = React.useState(false);
        const handleClick = () => {
            setSupportVisibility(!supportVisibility);
        };
        const sendEmail = async () => {
            var email = 'ashish.yadav@evorait.com';
            var subject = 'Report Issue For Cognite Reveal OMV';
            var emailBody = 'Error logs: ';

            const logs = await getAll();
            logs.map((log: logger) => {
                emailBody = emailBody + '\r\n' + log.message;
            });
            window.open('mailto:' + email + '?subject=' + subject + '&body=' + emailBody);
        };
        return (
            <div>
                <div>
                    <div className="supportContainer">
                        <div className="support">
                            <ContactSupportIcon onClick={handleClick} />
                        </div>
                    </div>
                </div>
                {supportVisibility ? (
                    <div className="supportContent">
                        <div className="supportContentText">
                            <p>Facing an issue with data or app?</p>
                            <p>You can report issue by sending email with details.</p>
                            <button onClick={sendEmail} className="btnReportIssue">
                                Report issue
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };

    const [error, setError] = React.useState<string | null>(null);
    const Infos = () => {
        const [infosVisibility, setInfosVisibility] = React.useState(false);
        const [modelId, setModelId] = React.useState(0);
        const [revisionId, setRevisionId] = React.useState(0);
        const [statusInfos, setStatusInfos] = React.useState<IdInfo | null>(null);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const section = params.get('section');
        const handleClick = () => {
            setInfosVisibility(!infosVisibility);
            const model = modelRef.current;
            if (model) {
                setModelId(model.modelId);
                setRevisionId(model.revisionId);
            } else {
                LogError('Infos: model is null');
            }
            const client = clientRef.current;
            if (client) {
                let status = client.login.status();
                status.then((result) => {
                    setStatusInfos(result);
                });
            } else {
                LogError('Infos: client is null');
            }
        };
        return (
            <div>
                <div className="infoContainer">
                    <div className="Info">
                        <InfoIcon onClick={handleClick} />
                    </div>
                </div>
                {infosVisibility || error ? (
                    <div className="infosContainer">
                        <div className="Infos">
                            <p>URL: {window.location.href}</p>
                            <p>ModelId: {modelId}</p>
                            <p>RevisionId: {revisionId}</p>
                            {section ? <p>Section: {section}</p> : null}
                            <p>Project: {statusInfos ? statusInfos.project : null}</p>
                            <br />
                            <code>{error}</code>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };

    const [hierchList, setHierchList] = React.useState(hierchArray);
    const HierchList = () => {
        const handleClick = (item: AssetNode | undefined) => {
            const cognite3DViewerManager = cognite3DViewerManagerRef.current;
            if (cognite3DViewerManager) {
                cognite3DViewerManager.focusAsset(item);
            } else {
                LogError('HierchList: cognite3DViewerManager is null');
            }
        };
        return (
            <div className="clickResultList">
                <p>Click results</p>
                <ul>
                    {hierchList.map((assetNode) => (
                        <li key={assetNode.id}>
                            {assetNode.name !== '' && !assetNode.asset && assetNode.name}
                            {assetNode.name === '' && !assetNode.asset && assetNode.id}
                            {assetNode.asset && assetNode.asset.name}
                            <button onClick={() => handleClick(assetNode)}>Select</button>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const Markups = () => {
        const [markUpVisibility, setMarkupVisibility] = React.useState(false);
        const [overlays, setOverlays] = React.useState<HtmlOverlayTool | null>(null);
        const handleClick = async () => {
            setMarkupVisibility(!markUpVisibility);
            const model = modelRef.current;
            const viewer = viewerRef.current;
            const client = clientRef.current;
            if (model && viewer && client) {
                if (overlays && markUpVisibility) {
                    overlays.clear();
                    model.setDefaultNodeAppearance(DefaultNodeAppearance.Default);
                } else {
                    const htmlOverlays = new HtmlOverlayTool(viewer);
                    const events = await client.events.list({ filter: { type: 'CogniteRemoteMarkup' } });
                    for (var i = 0; i < events.items.length; i++) {
                        const item = events.items[i];
                        if (item.metadata) {
                            const position = item.metadata['markupPointPosition'];
                            if (position) {
                                const points = position.split(',');
                                const el = createOverlay(item['description'] as string);
                                htmlOverlays.add(
                                    el,
                                    new Vector3(parseFloat(points[0]), parseFloat(points[1]), parseFloat(points[2]))
                                );
                            }
                        }
                    }
                    model.setDefaultNodeAppearance(DefaultNodeAppearance.Ghosted);
                    // const el = createOverlay('');
                    // htmlOverlays.add(el, new Vector3(194.9953405708334, 34.55221591216275, 144.33268803753685));
                    // const el1 = createOverlay('');
                    // htmlOverlays.add(el1, new Vector3(198.23390550573185, 34.88646742546186, 143.1254076062081));
                    // const el2 = createOverlay('');
                    // htmlOverlays.add(el2, new Vector3(217.30166618973152, 33.64372906186307, 131.72461243882563));
                    // const el3 = createOverlay('');
                    // htmlOverlays.add(el3, new Vector3(204.84710834232908, 33.644245477621496, 128.59021897770415));
                    // const assetNodes = new AssetNodeCollection(client, model);
                    // assetNodes.executeFilter({ assetId: 10047150 });
                    // model.assignStyledNodeCollection(assetNodes, {
                    //    renderGhosted: false,
                    // });
                    setOverlays(htmlOverlays);
                }
            } else {
                LogError('Markups: cannot show markup as model, client or viewer is null');
            }
        };
        return (
            <div className="markupContainer">
                <div className="markups">
                    {markUpVisibility ? (
                        <LocationOnIcon onClick={handleClick} />
                    ) : (
                        <LocationOffIcon onClick={handleClick} />
                    )}
                </div>
            </div>
        );
    };

    const FlocList = () => {
        const [list, setList] = React.useState(flocList);
        const [value, setValue] = React.useState('');
        const [resultVisibility, setResultVisibility] = React.useState(false);
        const [isLoading, setIsLoading] = React.useState(false);
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            const inputElement = e.target as HTMLInputElement;
            setValue(inputElement.value);
            oWebViewInterface.emit('InputValueChanged', value);
        };

        const handleSubmit = () => {
            setResultVisibility(false);
            const viewer = viewerRef.current;
            const client = clientRef.current;
            if (value && viewer && client) {
                setIsLoading(true);
                const assets = client.assets.search({ limit: 10, filter: {}, search: { query: value } });
                assets.then((results) => {
                    flocList = results;
                    setList(flocList);
                    setResultVisibility(true);
                    setIsLoading(false);
                });
            } else {
                LogError('FlocList: cannot show search result as model, client or viewer is null');
            }
        };

        const handleInputClick = () => {
            setResultVisibility(false);
        };

        const handleClick = (item: Asset | undefined) => {
            const cognite3DViewerManager = cognite3DViewerManagerRef.current;
            if (item && cognite3DViewerManager) {
                cognite3DViewerManager.gotoAsset(item);
            } else {
                LogError('FlocList: cognite3DViewerManager or item is null, click cannot be completed.');
            }
        };

        return (
            <div>
                <div className="searchBar">
                    <SearchIcon className="searchIcon" onClick={handleSubmit} />
                    <input
                        className="searchField"
                        value={value}
                        onChange={handleChange}
                        onClick={handleInputClick}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    />
                </div>
                {isLoading ? (
                    <div className="searchResults">
                        <ul>
                            <div className="spinner-container">
                                <div className="loading-spinner"></div>
                            </div>
                        </ul>
                    </div>
                ) : resultVisibility ? (
                    <div className="searchResults">
                        <ul>
                            {list.map((item) => (
                                <li key={item.id}>
                                    <button onClick={() => handleClick(item)}>
                                        <b>{item.externalId}</b>
                                        <br />
                                        <i>{item.description}</i>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </div>
        );
    };

    async function getExternalId(nodeId: CogniteInternalId): Promise<string> {
        const model = modelRef.current;
        const client = clientRef.current;
        const viewer = viewerRef.current;

        var externalId = '';

        if (nodeId && model && client && viewer) {
            client.revisions3D.list3DNodeAncestors(model.modelId, model.revisionId, nodeId).then(async (ancestors) => {
                const assetNodes: AssetNode[] = [];
                const nodes3d: Node3D[] = [];
                for (let it of ancestors.items) {
                    console.log(it);
                    const assetNode: AssetNode = {
                        id: it.id,
                        name: it.name,
                        treeIndex: it.treeIndex,
                    };
                    nodes3d[it.id] = it;
                    assetNodes.push(assetNode);
                }
                var label = '';
                var currentNodeId = nodeId;
                while (externalId === '') {
                    var result = await client.assetMappings3D.list(model.modelId, model.revisionId, {
                        limit: 1000,
                        nodeId: currentNodeId,
                    });
                    if (result.items.length === 0) {
                        if (nodes3d[currentNodeId].depth === 0) {
                            externalId = label;
                            if (externalId === '') {
                                externalId = 'Nothing found!';
                                return externalId;
                            }
                        } else {
                            currentNodeId = nodes3d[currentNodeId].parentId;
                            // we take the first non empty label in the hierarchy as a fallback if no Asset is found
                            if (label === '' && nodes3d[currentNodeId].name !== '') {
                                label = nodes3d[currentNodeId].name;
                            }
                        }
                    } else {
                        // If a mapping is found, read the asset
                        var response2 = await client.assets.retrieve([{ id: result.items[0].assetId }]);
                        // Finally we got the Functional Location or Equipment
                        if (response2.length > 0 && response2[0].externalId) {
                            externalId = response2[0].externalId;
                        }
                    }
                }
            });
        }
        return externalId;
    }

    async function getAsset(nodeId: CogniteInternalId): Promise<Asset | null> {
        const model = modelRef.current;
        const client = clientRef.current;
        const viewer = viewerRef.current;

        var asset: Asset | null = null;

        if (nodeId && model && client && viewer) {
            var ancestors = await client.revisions3D.list3DNodeAncestors(model.modelId, model.revisionId, nodeId);
            const assetNodes: AssetNode[] = [];
            const nodes3d: Node3D[] = [];
            for (let it of ancestors.items) {
                const assetNode: AssetNode = {
                    id: it.id,
                    name: it.name,
                    treeIndex: it.treeIndex,
                };
                nodes3d[it.id] = it;
                assetNodes.push(assetNode);
            }
            var currentNodeId = nodeId;
            while (nodes3d[currentNodeId].depth > 0) {
                var result = await client.assetMappings3D.list(model.modelId, model.revisionId, {
                    limit: 1000,
                    nodeId: currentNodeId,
                });
                if (result.items.length > 0) {
                    console.log('assetid: ' + result.items[0].assetId);
                    console.log('nodeid: ' + currentNodeId);
                    console.log('bb: ' + nodes3d[currentNodeId].boundingBox);
                    // If a mapping is found, read the asset
                    var response2 = await client.assets.retrieve([{ id: result.items[0].assetId }]);
                    // Finally we got the Functional Location or Equipment
                    if (response2.length > 0 && response2[0].externalId) {
                        return response2[0];
                    }
                } else {
                    currentNodeId = nodes3d[currentNodeId].parentId;
                }
            }
        }

        return asset;
    }

    function createOverlay(text: string, isAlert: boolean = false) {
        const element = document.createElement('img');
        element.setAttribute('src', 'assets/alert.png');
        element.setAttribute('class', 'circle');
        return element;
    }

    oWebViewInterface.on('GotoAsset', function (eventData: any) {
        const cognite3DViewerManager = cognite3DViewerManagerRef.current;
        if (eventData && typeof eventData == 'string') {
            const assetId = eventData as string;
            const client = clientRef.current;
            if (assetId && client && cognite3DViewerManager) {
                client.assets.search({ limit: 1, filter: {}, search: { query: assetId } }).then((results) => {
                    let item = results[0];
                    for (let result of results) {
                        if (result.externalId === assetId) {
                            item = result;
                        }
                    }
                    cognite3DViewerManager.gotoAsset(item);
                });
            }
        }
    });

    const [assetsMetadata, setAssetsMetadata] = React.useState<AssetMetadata[]>([]);
    const [selectedNodeId, setSelectedNodeId] = React.useState('');
    const [selectedAssetNodes, setSelectedAssetNodes] = React.useState<AssetNode[]>([]);
    const [value, setValue] = React.useState(0);

    const handleChange = (event: ChangeEvent<{}>, value: any) => {
        setValue(value);
    };

    useEffect(() => {
        async function main() {
            try {
                if (!divvi.current) {
                    return;
                }

                const search = window.location.search;
                const params = new URLSearchParams(search);
                const assetId = params.get('assetId');
                const section = params.get('section');
                const project = params.get('project');
                const equipments = params.get('__equiId')?.split(',');

                const clientManager = new ClientManager(project);
                clientRef.current = clientManager.client;
                const client = clientRef.current;

                const cognite3dViewerManager = new Cognite3DViewerManager(client, divvi.current, section);
                cognite3DViewerManagerRef.current = cognite3dViewerManager;
                viewerRef.current = cognite3dViewerManager.viewer;
                modelRef.current = await cognite3dViewerManager.model;
                const model = modelRef.current;
                const viewer = viewerRef.current;

                viewer.loadCameraFromModel(model);
                viewer.setBackgroundColor(new Color('#000000'));

                if (assetId) {
                    const assets = await client.assets.search({ limit: 1, filter: {}, search: { query: assetId } });
                    if (assets.length > 0) {
                        let cAsset = assets[0];
                        for (let asset of assets) {
                            if (asset.externalId === assetId) {
                                cAsset = asset;
                            }
                        }
                        const assetNode: AssetNode = {
                            asset: cAsset,
                        };
                        cognite3dViewerManager.focusAsset(assetNode);
                        setIsModelGhosted(true);
                    }
                }

                if (equipments) {
                    let assetIds: number[] = [];
                    const assetData = assetsMetadata;
                    equipments.forEach(async (assetId) => {
                        const assets = await client.assets.search({ limit: 1, filter: {}, search: { query: assetId } });
                        if (assets.length > 0) {
                            let cAsset = assets[0];
                            for (let asset of assets) {
                                if (asset.externalId === assetId) {
                                    cAsset = asset;
                                }
                            }

                            const assetNode: AssetNode = {
                                id: cAsset.id,
                                name: cAsset.name,
                                treeIndex: 0,
                            };
                            assetIds.push(cAsset.id);
                            const assetMetadata: AssetMetadata = {
                                assetName: cAsset.name,
                                assetDescription: cAsset.description ?? '',
                                isEquipment: true,
                                functionLoc: cAsset.metadata ? cAsset.metadata['Functional loc.'] ?? '' : undefined,
                                workCenter: cAsset.metadata ? cAsset.metadata['Work Center'] ?? '' : undefined,
                                plannerGroup: cAsset.metadata
                                    ? cAsset.metadata['Planner group'] ?? cAsset.metadata['plannerGroup'] ?? ''
                                    : undefined,
                                planningPlant: cAsset.metadata
                                    ? cAsset.metadata['Planning plant'] ?? cAsset.metadata['planningPlant'] ?? ''
                                    : undefined,
                                mainPlant: cAsset.metadata ? cAsset.metadata['MaintPlant'] ?? '' : undefined,
                                key: cAsset.metadata ? cAsset.metadata['key'] ?? '' : undefined,
                                installationDate: cAsset.metadata
                                    ? moment(cAsset.metadata['installationDate']).format('DD.MM.YYYY') ?? ''
                                    : undefined,
                                lastUpdatedTime: cAsset.metadata ? cAsset.metadata['lastUpdatedTime'] ?? '' : undefined,
                                location: cAsset.metadata ? cAsset.metadata['location'] ?? '' : undefined,
                                name: cAsset.metadata ? cAsset.metadata['name'] ?? '' : undefined,
                                operator: cAsset.metadata ? cAsset.metadata['operator'] ?? '' : undefined,
                                shortDescription: cAsset.metadata
                                    ? cAsset.metadata['shortDescription'] ?? ''
                                    : undefined,
                                assetNode: assetNode,
                            };
                            assetData.push(assetMetadata);
                        }
                    });
                    await cognite3dViewerManager.highlightAsset(assetIds);
                    setIsModelGhosted(true);
                    setAssetsMetadata(assetData);
                    setValue(assetsMetadata.some((item) => item.isEquipment) ? 2 : 1);
                }

                viewer.on('click', async (event) => {
                    viewer
                        .getIntersectionFromPixel(event.offsetX, event.offsetY)
                        .then(async (intersection) => {
                            if (intersection) {
                                console.log(
                                    'clicked!' +
                                        intersection.point.x +
                                        ' ' +
                                        intersection.point.y +
                                        ' ' +
                                        intersection.point.z
                                );

                                const iModel = (intersection as CadIntersection).model as Cognite3DModel;
                                const { modelId, revisionId } = iModel;
                                console.log('intersection.treeIndex: ' + (intersection as CadIntersection).treeIndex);
                                var nodeId = await iModel.mapTreeIndexToNodeId(
                                    (intersection as CadIntersection).treeIndex
                                );
                                console.log('nodeId: ' + nodeId);
                                setSelectedNodeId(nodeId.toString());

                                const allAssetMappedNodes = new TreeIndexNodeCollection(
                                    new NumericRange((intersection as CadIntersection).treeIndex, 1)
                                );
                                iModel.removeAllStyledNodeCollections();
                                iModel.setDefaultNodeAppearance(DefaultNodeAppearance.Default);
                                iModel.assignStyledNodeCollection(allAssetMappedNodes, {
                                    color: [0, 0, 255],
                                });

                                const assetNodes: AssetNode[] = [];
                                iModel
                                    .mapTreeIndexToNodeId((intersection as CadIntersection).treeIndex)
                                    .then(async (nodeId) => {
                                        const asset = await getAsset(nodeId);
                                        var ancestors = await client.revisions3D.list3DNodeAncestors(
                                            modelId,
                                            revisionId,
                                            nodeId
                                        );
                                        let assets = assetsMetadata;
                                        assets = assets.filter((item) => item.isEquipment);
                                        for (let it of ancestors.items) {
                                            if (it.id === nodeId) {
                                                const assetNode: AssetNode = {
                                                    id: it.id,
                                                    name: it.name,
                                                    treeIndex: it.treeIndex,
                                                };
                                                assetNodes.push(assetNode);
                                                if (asset) {
                                                    assetNode.asset = asset;
                                                    const assetMetadata: AssetMetadata = {
                                                        assetName: asset.name,
                                                        assetDescription: asset.description ?? '',
                                                        isEquipment: (asset.metadata && !!asset.source) ?? false,
                                                        functionLoc: asset.metadata
                                                            ? asset.metadata['Functional loc.'] ?? ''
                                                            : undefined,
                                                        workCenter: asset.metadata
                                                            ? asset.metadata['Work Center'] ?? ''
                                                            : undefined,
                                                        plannerGroup: asset.metadata
                                                            ? asset.metadata['Planner group'] ??
                                                              asset.metadata['plannerGroup'] ??
                                                              ''
                                                            : undefined,
                                                        planningPlant: asset.metadata
                                                            ? asset.metadata['Planning plant'] ??
                                                              asset.metadata['planningPlant'] ??
                                                              ''
                                                            : undefined,
                                                        mainPlant: asset.metadata
                                                            ? asset.metadata['MaintPlant'] ?? ''
                                                            : undefined,
                                                        key: asset.metadata ? asset.metadata['key'] ?? '' : undefined,
                                                        installationDate: asset.metadata
                                                            ? moment(asset.metadata['installationDate']).format(
                                                                  'DD.MM.YYYY'
                                                              ) ?? ''
                                                            : undefined,
                                                        lastUpdatedTime: asset.metadata
                                                            ? asset.metadata['lastUpdatedTime'] ?? ''
                                                            : undefined,
                                                        location: asset.metadata
                                                            ? asset.metadata['location'] ?? ''
                                                            : undefined,
                                                        name: asset.metadata ? asset.metadata['name'] ?? '' : undefined,
                                                        operator: asset.metadata
                                                            ? asset.metadata['operator'] ?? ''
                                                            : undefined,
                                                        shortDescription: asset.metadata
                                                            ? asset.metadata['shortDescription'] ?? ''
                                                            : undefined,
                                                        assetNode: assetNode,
                                                    };

                                                    assets.push(assetMetadata);
                                                }
                                            }
                                        }
                                        setAssetsMetadata(assets);
                                        setValue(assetsMetadata.some((item) => item.isEquipment) ? 2 : 1);
                                        setSelectedAssetNodes(assetNodes);
                                    });
                            }
                        })
                        .catch((err) => {
                            if (typeof err == 'string') {
                                setError(err);
                                LogError('viewer: click: ' + err);
                            } else {
                                setError(err.stack);
                                LogError('viewer: click: ' + err.stack);
                            }
                        });
                });
            } catch (e: any) {
                if (typeof e == 'string') {
                    setError(e);
                    LogError('main: ' + e);
                } else {
                    setError(e.stack);
                    LogError('main: ' + e.stack);
                }
            }
        }

        main();

        return () => {
            const viewer = viewerRef.current;
            if (viewer) {
                viewer.dispose();
            }
        };
    }, []);

    const handleSelectClick = (assetNode: AssetNode) => {
        const cognite3DViewerManager = cognite3DViewerManagerRef.current;
        if (cognite3DViewerManager) {
            cognite3DViewerManager.focusAsset(assetNode);
        }
    };

    return (
        <div className="App">
            <div
                ref={overlayDiv}
                id="overlay-demo"
                style={{
                    position: 'absolute',
                    top: 10,
                    left: 10,
                    zIndex: 1,
                    padding: '10px',
                    width: '350px',
                    minHeight: '50px',
                }}
            >
                <FlocList />
            </div>

            <div className="unghostContainer">
                <Unghost />
            </div>
            <Infos />
            <Markups />
            <Support />

            <div className="divvi" ref={divvi} />

            {assetsMetadata.length > 0 ? (
                <div className="sidebar">
                    <div className="header">
                        <p className="subHeader">{selectedNodeId}</p>
                    </div>
                    <TabContext value={value.toString()}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="more information" textColor="secondary">
                                <Tab label="Function Location" value="1" />
                                <Tab label="Equipment" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            {assetsMetadata
                                .filter((item) => !item.isEquipment)
                                .map((asset) => (
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={
                                                <ExpandMoreIcon onClick={() => handleSelectClick(asset.assetNode)} />
                                            }
                                            id="header"
                                        >
                                            <Typography sx={{ color: 'text.secondary' }}>
                                                {asset.assetDescription}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <p>
                                                    <span className="title">Function Location: </span>
                                                    <span className="title-content">{asset.functionLoc}</span>
                                                </p>
                                                <p>
                                                    <span className="title">Work Center: </span>
                                                    <span className="title-content">{asset.workCenter}</span>
                                                </p>
                                                <p>
                                                    <span className="title">Main Plant: </span>
                                                    <span className="title-content">{asset.mainPlant}</span>
                                                </p>
                                                <p>
                                                    <span className="title">Planning Plant: </span>
                                                    <span className="title-content">{asset.planningPlant}</span>
                                                </p>
                                                <p>
                                                    <span className="title">Planner Group: </span>
                                                    <span className="title-content">{asset.plannerGroup}</span>
                                                </p>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                        </TabPanel>
                        <TabPanel value="2">
                            {assetsMetadata
                                .filter((item) => item.isEquipment)
                                .map((asset) => (
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={
                                                <ExpandMoreIcon onClick={() => handleSelectClick(asset.assetNode)} />
                                            }
                                            id="header"
                                        >
                                            <Typography sx={{ color: 'text.secondary' }}>
                                                {asset.assetDescription}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <p>
                                                    <span className="title">Equipment: </span>
                                                    <span className="title-content">{asset.name}</span>
                                                </p>
                                                <p>
                                                    <span className="title">Description: </span>
                                                    <span className="title-content">{asset.shortDescription}</span>
                                                </p>
                                                <p>
                                                    <span className="title">Installation Date: </span>
                                                    <span className="title-content">{asset.installationDate}</span>
                                                </p>
                                                <p>
                                                    <span className="title">Planning Plant: </span>
                                                    <span className="title-content">{asset.planningPlant}</span>
                                                </p>
                                                <p>
                                                    <span className="title">Planner Group: </span>
                                                    <span className="title-content">{asset.plannerGroup}</span>
                                                </p>
                                                <p>
                                                    <span className="title">Last Update Time: </span>
                                                    <span className="title-content">{asset.lastUpdatedTime}</span>
                                                </p>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                        </TabPanel>
                    </TabContext>
                </div>
            ) : null}
        </div>
    );
}

export default App;
