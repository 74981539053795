export const DBConfig = {
    name: 'CogniteReveal',
    version: 1,
    objectStoresMeta: [
      {
        store: 'logger',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'message', keypath: 'message', options: { unique: false } }
        ]
      }
    ]
  };