import {
    AssetNodeCollection,
    Cognite3DModel,
    Cognite3DViewer,
    DefaultNodeAppearance,
    NodeOutlineColor,
    TreeIndexNodeCollection,
} from '@cognite/reveal';
import { Asset, CogniteClient } from '@cognite/sdk';
import { Box3 } from 'three';
import { env } from '../env';
import { AssetNode } from './interface/AssetNode';
import { Section } from './enums/Section';

export class Cognite3DViewerManager {
    readonly viewer: Cognite3DViewer;
    private readonly client: CogniteClient;
    model: Promise<Cognite3DModel>;

    constructor(client: CogniteClient, domElement: HTMLElement | undefined, section: string | null) {
        this.client = client;
        this.viewer = new Cognite3DViewer({ sdk: client, domElement: domElement });
        this.model = this.addModel(section ?? Section.MPB);
    }

    async addModel(section: string) {
        let modelId;
        let revisionId;
        switch (section.toUpperCase()) {
            case Section.MPA:
                modelId = this.client.project.includes('dev')
                    ? env.cad.mpa.modelIdDev
                    : this.client.project.includes('test')
                    ? env.cad.mpa.modelIdQua
                    : env.cad.mpa.modelId;
                revisionId = this.client.project.includes('dev')
                    ? env.cad.mpa.revisionIdDev
                    : this.client.project.includes('test')
                    ? env.cad.mpa.revisionIdQua
                    : env.cad.mpa.revisionId;
                break;
            case Section.MPS:
                modelId = this.client.project.includes('dev')
                    ? env.cad.mps.modelIdDev
                    : this.client.project.includes('test')
                    ? env.cad.mps.modelIdQua
                    : env.cad.mps.modelId;
                revisionId = this.client.project.includes('dev')
                    ? env.cad.mps.revisionIdDev
                    : this.client.project.includes('test')
                    ? env.cad.mps.revisionIdQua
                    : env.cad.mps.revisionId;
                break;
            case Section.OPM:
                modelId = this.client.project.includes('dev')
                    ? env.cad.opm.modelIdDev
                    : this.client.project.includes('test')
                    ? env.cad.opm.modelIdQua
                    : env.cad.opm.modelId;
                revisionId = this.client.project.includes('dev')
                    ? env.cad.opm.revisionIdDev
                    : this.client.project.includes('test')
                    ? env.cad.opm.revisionIdQua
                    : env.cad.opm.revisionId;
                break;
            case Section.MPB:
            default:
                modelId = this.client.project.includes('dev')
                    ? env.cad.mpb.modelIdDev
                    : this.client.project.includes('test')
                    ? env.cad.mpb.modelIdQua
                    : env.cad.mpb.modelId;
                revisionId = this.client.project.includes('dev')
                    ? env.cad.mpb.revisionIdDev
                    : this.client.project.includes('test')
                    ? env.cad.mpb.revisionIdQua
                    : env.cad.mpb.revisionId;
                break;
        }
        const model = (await this.viewer.addModel({
            modelId: modelId,
            revisionId: revisionId,
        })) as Cognite3DModel;
        return model;
    }

    async focusAsset(item: AssetNode | undefined) {
        const modelRef = await this.model;
        if (modelRef && this.viewer) {
            if (item) {
                // modelRef.removeAllStyledNodeCollections();
                if (!item.asset && item.treeIndex && item.id) {
                    modelRef.mapTreeIndexToNodeId(item.treeIndex);
                    const bb = modelRef.getBoundingBoxByNodeId(item.id);
                    bb.then((box3d) => {
                        this.viewer.fitCameraToBoundingBox(box3d);
                    });
                } else if (item.asset) {
                    this.gotoAsset(item.asset, false);
                }
            }
        }
    }

    async highlightAsset(nodes: number[]) {
        const modelRef = await this.model;
        let treeIndexes: number[] = [];
        if (modelRef && this.client && this.viewer) {
            const assetMappings3D = await this.client.assetMappings3D.filter(modelRef.modelId, modelRef.revisionId, {
                filter: {
                    assetIds: nodes,
                },
            });
            treeIndexes = assetMappings3D.items.map((item) => item.treeIndex);
            const treeNodes = new TreeIndexNodeCollection(treeIndexes);
            modelRef.setDefaultNodeAppearance(DefaultNodeAppearance.Ghosted);
            modelRef.assignStyledNodeCollection(treeNodes, {
                renderGhosted: false,
                outlineColor: NodeOutlineColor.White,
                color: [255, 255, 255],
            });
        }
    }

    async gotoAsset(item: Asset, styleNode: boolean = true) {
        const modelRef = await this.model;
        if (item && modelRef && this.client && this.viewer) {
            const assetMappings3D = this.client.assetMappings3D.list(modelRef.modelId, modelRef.revisionId, {
                limit: 1000,
                assetId: item.id,
            });
            if (styleNode) {
                const allAssetMappedNodes = new AssetNodeCollection(this.client, modelRef);
                allAssetMappedNodes.executeFilter({ assetId: item.id });
                modelRef.setDefaultNodeAppearance(DefaultNodeAppearance.Ghosted);
                modelRef.assignStyledNodeCollection(allAssetMappedNodes, {
                    renderGhosted: false,
                    outlineColor: NodeOutlineColor.White,
                    color: [255, 255, 255],
                });
            }

            assetMappings3D.then(async (results) => {
                if (results.items.length > 0) {
                    let first = true;
                    let i = 0;
                    let bbs: Box3[] = [];
                    for (let assetMapping of results.items) {
                        if (first) {
                            first = false;
                            const bb = await modelRef.getBoundingBoxByNodeId(assetMapping.nodeId);
                            bbs.push(bb);
                            if (results.items.length === 1) {
                                this.viewer.fitCameraToBoundingBox(bb);
                            }
                        }
                        if (i === results.items.length - 1) {
                            const bb = await modelRef.getBoundingBoxByNodeId(assetMapping.nodeId);

                            bbs.push(bb);
                            if (bbs.length > 1) {
                                bbs = [bbs[0].union(bbs[1])];
                            }
                            if (bbs.length > 0) {
                                const bb = bbs[0];
                                this.viewer.fitCameraToBoundingBox(bb);
                            }
                        }
                        console.log('node: ' + assetMapping.nodeId);
                        i++;
                    }
                }
            });
        }
    }
}
